@import "~styles/theme/index";

.#{$wf__ns}permitting-jurisdiction-audit {
  .ant-collapse-content {
    padding: 30px;
  }

  &__section {
    margin-bottom: 25px;

    &__form {
      display: grid;
      grid-template-columns: 1fr 1fr;

      .left {
        grid-column: 1 / 2;
      }

      .right {
        grid-column: 2 / 3;
      }

      .ant-form-item {
        align-items: center;
        height: 40px;
      }
    }

    .ant-divider.ant-divider-horizontal {
      border-color: $blue12;
    }
  }

  &__actions {
    > *:not(:last-of-type) {
      margin-right: 15px;
    }
  }
}
