@import "~styles/theme/index";

.#{$wf__ns}sof-tool-configuration-panel {
  display: flex;
  margin-bottom: 25px;

  &__form {
    display: flex;

    .ant {
      &-picker {
        width: 190px;
      }

      &-form-item {
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0 20px 0 0;

        &:last-of-type {
          margin: 0 30px 0 0;
        }
      }

      &-form-item-control {
        width: 200px;
      }
    }
  }
}
