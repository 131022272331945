@import "../theme/index";

$icon-size: 10px;

@mixin no-border-radius($position) {
  border-#{$position}: {
    left-radius: 0;
    right-radius: 0;
  }
}

.circle {
  display: inline-block;
  min-width: $icon-size;
  width: $icon-size;
  height: $icon-size;
  border-radius: 50%;

  &_high {
    background-color: $yellow;
  }

  &_primary {
    background-color: $primary;
  }

  &_inactive {
    background-color: $red1;
  }

  &_green {
    background-color: $turquoise;
  }

  &_gray {
    background-color: $gray2;
  }

  &_secondary {
    background-color: $font-color;
  }

  &_orange {
    background-color: $orange;
  }

  &_yellow {
    background-color: $yellow;
  }
}

.no-border-radius {
  &__bottom {
      @include no-border-radius('bottom');
  }

  &__top {
      @include no-border-radius('top');
  }
}

.primary-btn {
  &_default {
    &:hover,
    &:focus,
    &:active {
      background: none;
    }

    &:hover,
    &:focus {
      border-color: $blue7;
      color: $blue7;
    }

    &:active {
      border-color: $blue1;
      color: $blue1;
    }
  }

  &_primary {
    background: $primary;
    color: $white;

    &:hover,
    &:focus,
    &:active {
      background: $hover-primary;
      color: $white;
    }

    &:hover,
    &:focus {
      border-color: $hover-primary;
    }

    &:active {
      border-color: $blue1;
    }
  }

  &_danger {
    background: $danger;
    color: $white;
    border: none;

    &:hover,
    &:focus,
    &:active {
      background: $red1;
    }
  }

  &_danger-outline {
    border-color: $danger;
    color: $danger;

    &:hover,
    &:focus,
    &:active {
      background: none;
    }

    &:hover,
    &:focus,
    &:active {
      border-color: $red1;
      color: $red1;
    }
  }
}

.tabs-wrap {
  position: relative;

  & > .ant-collapse > .ant-collapse-item {
    .ant-collapse-content {
      height: calc(100vh - 430px);
      overflow-y: auto;
      overflow-x: hidden;
      position: relative;
    }
  }

  &_with-actions > .ant-collapse > .ant-collapse-item {
    .ant-collapse-content {
      height: calc(100vh - 490px);
      overflow-y: auto;
      overflow-x: hidden;
      margin-bottom: 24px;
      position: relative;
    }
  }
}

.controls {
  display: flex;

  &.controls-edit-mode {
    flex-direction: row-reverse;
  }

  .ant-btn {
    border: none;

    &.active,
    &:active,
    &:focus,
    &:hover {
      background: transparent;
    }
  }
}

.invisible {
  display: none;
}

.text-wrapper {
  white-space: pre-wrap;
  overflow-wrap: break-word;
}
