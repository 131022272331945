@import "../theme/index";
@import "~antd/dist/antd.min.css";

.ant-menu {
  background: $blue3;
  color: $secondary;

  &.ant-menu-sub,
  &.ant-menu-submenu.ant-menu-submenu-popup {
    background: $blue3;
  }

  &.ant-menu-sub.ant-menu-vertical {
    border: none;
    color: $white;
  }

  .ant-menu-item:active,
  .ant-menu-submenu-title:active,
  &:not(.ant-menu-horizontal) > .ant-menu-item-selected {
    background: transparent;
  }

  &.ant-menu-horizontal,
  &.ant-menu-submenu-horizontal {
    border: none;
  }

  &.ant-menu-inline,
  &.ant-menu-vertical {
    border: none;
  }

  .ant-menu-item > a {
    color: $secondary;
  }

  .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
    &::before,
    &::after {
      background-image: none;
      background: $secondary;
    }
  }

  .ant-menu-submenu-title {
    display: flex;
    align-items: center;
  }
}

//redefinition of inline styles
.ant-layout-sider {
  background: $blue3;
  flex: 0 0 275px !important;
  max-width: 275px !important;
  width: 100% !important;

  &.ant-layout-sider-collapsed {
    flex: 0 0 72px !important;
    max-width: 72px !important;
  }
}

.ant-switch {
  &#{&}-checked {
    background-color: $blue5;
  }

  &::after {
    background: $primary;
  }
}

.ant-collapse {
  border: none;
  background: none;
  border-radius: 4px;
  $self: &;

  & > &-item,
  & > &-item:last-child {
    border: none;

    & > #{$self}-header {
      background: $blue3;
      color: $gray1;
      border-color: $blue3;
      border-radius: 4px;
    }

    & > #{$self}-content {
      border-radius: 0 0 4px 4px;
    }
  }

  &-content {
    $self: &;
    background: $blue4;
    color: $font-color;
    border: none;
    overflow-y: auto;

    & > #{$self}-box {
      padding: 0;
    }
  }
}

.react-tel-input .form-control {
  background: transparent;
  caret-color: $white;
  color: $white;
  border-color: $input-border;
  width: $full-res;

  &:active,
  &:focus {
    border-color: $blue5;
  }

  & ~ .flag-dropdown {
    &:hover {
      .selected-flag {
        background: $gray4;
      }
    }

    .selected-flag {
      .flag {
        left: 50%;
        transform: translateX(-50%);
      }
    }

    top: 1px;
    bottom: 1px;
    left: 1px;
    background: $gray4;
    border: none;
  }
}

.ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background: transparent;
  filter: opacity(0.5);
  color: $white;
}

.ant-input,
.ant-input-password,
.ant-select,
.ant-radio,
.ant-checkbox,
.ant-input-number {
  .ant-form-item-has-error & {
    background: transparent;

    &:active,
    &:focus,
    &:hover,
    &[disabled] {
      background: transparent;
    }
  }

  &-selector,
  &,
  &-single:not(.ant-select-customize-input) > &-selector,
  & > &-inner,
  &-wrapper {
    background: transparent;
    caret-color: $white;
    color: $white;
    border-color: $gray3;
    font-size: 13px;

    svg {
      color: $gray3;
    }
  }

  &-disabled, &-wrapper-disabled {
    cursor: not-allowed;

    + span {
      color: $gray2;
    }
  }

  &[disabled] {
    color: $gray2;
    background: transparent;
  }

  input::placeholder {
    color: $gray2;
  }
}

.ant-picker {
  $self: &;
  display: flex;
  align-items: center;
  border-radius: 4px;

  &-input {
    & > input {
      font-size: 13px;
      color: $white;
      line-height: 22px;
    }

    #{$self}-clear {
      background: transparent;
    }

    &:hover #{$self}-suffix {
      opacity: 0;
    }
  }

  &-dropdown {
    #{$self}-panel-container {
      #{$self}-panel {
        border-width: 0;
      }

      background: $blue3;

      #{$self}-content th {
        color: $gray1;
      }

      #{$self}-cell {
        &:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) {
          #{$self}-cell-inner {
            background: $blue1;
          }
        }

        &-in-view {
          color: $gray1;
        }

        color: $gray2;
      }

      #{$self}-header {
        background: $gray3;
        border-bottom: none;
        color: $gray1;

        > button {
          color: $gray2;
        }
      }

      #{$self}-footer {
        border-top-color: $gray3;
      }
    }
  }

  &.ant-picker-disabled {
    border-color: $gray3;
    background-color: transparent;
    opacity: .5;

    input[disabled] {
      color: $white;
    }
  }
}

textarea.ant-input {
  resize: none;
  overflow-y: auto;
}

.ant-input-number {
  $self: &;

  &-handler-wrap {
    opacity: 1;
    background: transparent;
    border: none;

    #{$self}-handler {
      &-down {
        border: none;
      }
    }
  }
}

.ant-form {
  &-item {
    margin: 0 0 15px;
    padding: 0 1em;

    &-label {
      white-space: normal;

      & > label {
        color: $font-color;
        font-size: $font-size;
      }
    }

    &-explain {
      font-size: 12px;
    }
  }

  input.ant-input {
    height: auto;
  }

  .ant-input {
    display: flex;
    height: 40px;
    border-radius: 4px;

    &-affix-wrapper-disabled:hover {
      border-color: $gray3;
    }


    &.model-input {
      display: flex;
      align-items: center;
      height: 40px;
      line-height: 28px;
    }

    &-number {
      width: $full-res;
    }

    &-suffix {
      > .ant-input-clear-icon {
        margin: 0;
      }

      > .anticon-close-circle:not(.ant-input-clear-icon-hidden) ~ .anticon-calendar {
        display: none;
      }
    }
  }

  .ant-select {
    $self: &;

    &-selector {
      border-color: $gray2;
    }

    &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 40px;
      border-radius: 4px;

      > .ant-select-selection-item,
      > .ant-select-selection-search {
        line-height: 38px;
      }
    }

    &-single#{$self} {
      #{$self}-selector {
        height: 40px;

        #{$self}-selection-search {
          > &-input.ant-select-single:not(.ant-select-customize-input).ant-select-selector {
            height: 40px;
          }
        }
      }
    }
  }

  .react-tel-input .form-control {
    height: 40px;
  }
}

.ant-select {
  $self: &;

  &-dropdown {
    background: $select-background;
  }

  &-multiple#{$self} {
    #{$self}-selector {
      #{$self}-selection-item {
        background-color: rgba(24, 144, 255, 0.2);
        border-color: $primary;
        color: $primary;

        &-remove svg {
          color: $primary;
        }
      }
    }
  }
}

.ant-select-multiple .ant-select-selector,
.ant-select-multiple .ant-select-selection-item,
.ant-select-item-option-content,
.ant-select-item-option-active:not(.ant-select-item-option-disabled),
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: inherit;
  color: $white;
}

.ant-select-item-option-disabled {
  > .ant-select-item-option-content {
    color: $gray2;
  }
}

.ant-btn {
  background: transparent;
  color: $primary;
  border-color: $primary;
  height: 40px;
  border-radius: 5px;

  &[disabled] {
    color: $gray2;
    background: $gray2;
    pointer-events: none;
    border: none;
  }
}

.ant-tabs {
  $self: &;

  &-bar {
    background: $blue4;
    border: none;
    padding: 0 32px;
    border-bottom: {
      left-radius: 4px;
      right-radius: 4px;
    }
    margin-bottom: 24px;
  }

  &-nav {
    #{$self}-tab {
      font-size: $font-size;
      margin-right: 40px;
    }
  }

  color: $gray2;
}

.ant-empty {
  &-description {
    color: $no-data;
  }

  &-img-simple {
    &-path {
      fill: $no-data;
    }

    &-g {
      stroke: $no-data;
    }
  }
}

.ant-table {
  $self: &;
  background: none;

  &-header {
    border-radius: 4px 4px 0 0;
  }

  &-tbody {
    > tr#{$self}-placeholder {
      background: $select-background;

      &:hover > td {
        background: $select-background;
      }
    }

    > tr > td {
      border: none;
    }
  }

  &-cell {
    &.overflow {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .list {
      display: flex;
      flex-direction: column;

      &__item:not(:last-of-type) {
        margin-bottom: 5px;
      }
    }
  }
}

.ant-modal {
  $self: &;

  &-content {
    background: $blue3;
    border-radius: 7px;

    #{$self}-close {
      color: $gray1;
    }

    #{$self}-header {
      background: $gray3;
      border-bottom: none;
      border-radius: 7px 7px 0 0;

      #{$self}-title {
        color: $gray1;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
      }
    }

    #{$self}-body {
      padding: 40px 0 15px 0;

      .ant-form-item {
        display: flex;
      }
    }

    #{$self}-footer {
      padding: 0 0 40px;
      border-top: none;
      text-align: center;
      display: flex;
      justify-content: center;
      flex-direction: row-reverse;

      .ant-btn {
        width: 150px;

        &:first-child {
          margin-left: 15px;
        }
      }
    }
  }
}

.ant-card {
  background: $blue9;
  color: $secondary;

  &-body {
    padding: 0;
  }
}

.ant-popover {
  &-placement-bottomLeft {
    padding-top: 7px;
  }

  &-arrow {
    display: none;
  }

  &-inner {
    background: none;

    &-content {
      padding: 0;
    }
  }
}

.ant-upload {
  &-drag {
    min-height: 190px;
    display: flex;
    align-items: center;
  }
}

.ant-notification-notice {
  padding: 37px 15px 40px 32px;
  border-radius: 7px;
  background-color: $blue5;
  letter-spacing: 0.5px;
  font-weight: 500;

  .ant-notification-close-x .anticon svg {
    fill: $gray2;
  }

  &-with-icon &-message {
    margin-bottom: 15px;
    color: $gray1;
    font-size: 20px;
    line-height: 1;
  }

  &-description {
    font-size: 13px;
    line-height: 13px;
    color: $gray2;
  }
}

.ant-form-item-control-input-content {
  color: $white;
}

.ant-radio-disabled .ant-radio-inner::after {
  background-color: $primary;
}

.ant-checkbox-disabled .ant-checkbox-inner {
  border-color: $gray2 !important;
}

.read-only-checkbox .ant-checkbox.ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner:after,
.ant-checkbox.ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner:after {
  border-color: $white !important;
}

a.ant-btn {
  padding: inherit;
}

.prov-input-number {
  .ant-input-number {
    height: 40px;
    border-radius: 4px;

    &-input-wrap {
      height: inherit;

      > input {
        height: inherit;
      }
    }
  }
}
