@import "~styles/theme/index";

.#{$wf__ns}admin-vendors {
  &__panel {
    margin-bottom: 24px;

    .add-btn > .ant-btn {
      margin-top: 30px;
      min-width: 130px;
    }

    .controls {
      display: flex;
      align-items: center;

      .ant-btn {
        border: none;
        color: $gray2;

        span > svg {
          width: 24px;
          height: 24px;
        }

        &.active,
        &:active,
        &:focus,
        &:hover {
          background: transparent;
        }
      }
    }
  }

  .ant-table-tbody > tr > td {
    padding: 20px 16px;
  }

  .ant-collapse-content {
    padding: 30px 32px;
  }

  .ant-collapse > .ant-collapse-item > div.ant-collapse-header {
    background: $blue5;
    font-weight: 500;
    font-size: 17px;
    line-height: 28px;
    color: $white;
    padding-left: 32px;
  }

  .distribution-panel {
    &__handler {
      display: flex;
      align-items: flex-start;
      margin-bottom: 30px;

      .ant-form-item {
        display: flex;
      }

      div.ant-select-selector {
        width: 325px;
        height: 40px;
        display: flex;
        align-items: center;
      }

      .ant-input {
        height: 40px;
      }
    }

    &_add-btn {
      margin-left: 32px;

      &.add-list-btn {
        margin-right: 60px;
      }
    }

    .controls {
      display: flex;
      justify-content: flex-end;

      &__btn:first-of-type {
        display: none;
      }
    }
  }
}
