@import "~styles/theme/index";

.#{$wf__ns}equipment-manufacturer {
    width: $full-res;
    background: $blue4;
    border-radius: 10px;
    padding: 2em;

    &__add-button {
        width: 130px;
        margin-top: 30px;
    }

    &__table-handler {
      .ant-form-item {
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 0 0 24px;

        &-label {
          display: flex;
          margin-bottom: 10px;
        }

        &-control {
          min-width: 180px;
        }

        .ant-select-selector {
          display: flex;
          align-items: center;
        }
      }
    }

    .controls {
        &__btn {
          width: 20px;
          height: 20px;
          padding: 0;
          border: none;
          cursor: pointer;

          &:first-child {
            margin-right: 25px;
          }

          &:hover,
          &:active,
          &:focus {
            background: none;
          }

          img {
            width: $full-res;
          }
        }
      }

      .prov-primary-button__icon {
        margin-right: 10px;
      }
}
