@import "~styles/theme/index";

.#{$wf__ns}sof-tool-upload-button {
  display: flex;
  align-items: center;

  &__file-info {
    display: flex;
    align-items: center;
    padding: 0 30px 0 25px;
    color: $white;

    >.document-icon {
      margin-right: 15px;
    }

    >.file-name {
      max-width: 300px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  > .ant-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    padding: 0;
    border: none;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
      background: none;
    }
  }
}
