.prov-sof-preview {
  &__content {
    width: 100%;
    height: 85vh;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    overflow: hidden;
  }

  .iframe {
    width: 100%;
    height: 100%;
  }

  &.ant-modal {
    top: 50px;
  }

  .ant-modal-content {
    .ant-modal-body,
    .ant-modal-footer {
      padding: 0;
    }
  }
}
