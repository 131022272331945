@import "~styles/theme/index";

.#{$wf__ns}admin-state-modal {
  .ant-modal {
    &-body {
      .ant-row.ant-form-item {
        justify-content: center;

        .ant-input-number {
          height: 40px;
        }
      }
    }

    &-footer {
      margin-left: 130px;

      .ant-btn {
        width: 150px;
        margin-right: 28px;

        &.primary-btn_default {
          color: $white;
          background: $danger;
          border-color: $danger;
        }
      }
    }
  }


  &.add-modal {
    .ant-modal-footer {
      margin-left: -56px;

      .ant-btn {
        width: 150px;

        &.primary-btn_default {
          display: none;
        }
      }
    }
  }
}
