@import "~styles/theme/index";

.scoping {
  &-browse {
    .ant-picker {
      height: 40px;
    }

    .download-btn {
      margin-right: 70px;
    }

    .switch-wrap {
      margin-top: 40px;
    }

    &__schedule-form {
      margin-top: 2em;

      .ant-row.ant-form-item {
        padding-left: 0;
      }
    }

    &__modal {
      .ant-modal-content .ant-modal-footer {
        justify-content: flex-start;
        margin-left: 204px;
      }
    }
  }
}
