@import "~styles/theme/index";

.#{$wf__ns}project-category {
  width: $full-res;
  background: $blue4;
  border-radius: 10px;
  padding: 2em;
  margin-bottom: 24px;

  .prov-custom-table {
    overflow-y: auto;
    max-height: 170px;
  }

  &__add-button {
    width: 120px;
    margin-top: 1.5em;
  }

  .circle {
    margin-right: 10px;
  }

  .primary {
    color: $primary;
  }

  .secondary {
    color: $white;
  }

  .controls {
    &__btn {
      width: 20px;
      height: 20px;
      padding: 0;
      border: none;
      cursor: pointer;

      &:first-child {
        margin-right: 25px;
      }

      &:hover,
      &:active,
      &:focus {
        background: none;
      }

      img {
        width: $full-res;
      }
    }
  }

  .prov-primary-button__icon {
    margin-right: 10px;
  }

  .ant-table-cell {
    @include tb {
      padding: 15px;
    }
  }

}

