@import "~styles/theme/index";

.#{$wf__ns}duplicate-sector-modal {
  .ant-modal-content .ant-modal-footer {
    flex-direction: row;
    padding-left: 2em;
  }

  .ant-modal {
    &-body {
      padding-left: 1em;

      .ant-row.ant-form-item {
        justify-content: center;
      }
    }

    &-footer {
      .primary-btn {
        &_default {
          display: none;
        }

        &_primary {
          width: 150px;
        }
      }
    }
  }
}
