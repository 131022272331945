.leasing-browse__modal {
  &.prov-browse-page__filter-modal .ant-modal-content {
    .ant-modal-body {
      padding: 30px 50px 15px;
    }

    .ant-modal-footer {
      margin-right: 0;
      margin-left: 140px;
      padding-bottom: 30px;

      .ant-btn {
        max-width: 130px;

        &.primary-btn_primary {
          margin-right: 17px;
        }
      }
    }
  }
}
