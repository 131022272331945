@import "~styles/theme/index";

.#{$wf__ns}workflow-permitting-section {
  margin-bottom: 25px;

  &__form {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    .ant-form-item {
      align-items: center;
      height: 40px;

      &:not(:last-of-type) {
        margin-right: 30px;
      }
    }
  }

  .ant-collapse-content {
    padding: 30px;
  }

  .ant-divider.ant-divider-horizontal {
    border-color: $blue12;
  }
}
