@import "~styles/theme/index";

.#{$wf__ns}equipment-manufacturer-modal {
  .ant-modal-content {
    .ant-modal-body {
      .ant-row.ant-form-item {
        justify-content: center;

        .ant-input-number {
          height: 40px;
        }
      }
    }

    .ant-modal-footer {
      margin-right: 22px;
      margin-left: 166px;
      justify-content: flex-start;

      .ant-btn {
        width: 150px;
        margin-right: 38px;
      }
    }
  }
}
