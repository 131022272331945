// #RESOLUTION
$full-res: 100%;

// #NAMESPACE
$wf__ns: prov- !default;
$wf__anim: $wf__ns + anim__;

// #MAIN COLORS
$primary: #1890FF;
$hover-primary: #40A9FF;
$secondary: rgba(255, 255, 255, 0.65);
$danger: #F64D4F;

// #COMMON COLORS
$white: #ffffff;
$black: #000000;
$blue1: #005AE1;
$blue2: #15528B;
$blue3: #0E284C;
$blue4: #0B1F3B;
$blue5: #153D74;
$blue6: #0A1F3D;
$blue7: #40a9ff;
$blue8: #104780;
$blue9: #14335E;
$blue10: #006DD2;
$blue11: rgba(24, 144, 255, 0.35);
$blue12: rgba(22, 64, 122, 0.9);
$select-background: #133463;
$no-data: #A8ACB2;
$dark-blue: #071223;
$red1: #EB5757;
$red2: #C74244;
$red-transp: rgba(246, 77, 79, 0.1);
$red-transp2: rgba(246, 77, 79, 0.2);
$error-red-transp: rgba(246, 77, 79, 0.2);;
$orange: #F2994A;
$orange-transp: rgba(242, 153, 74, 0.2);
$yellow: #F2C94C;
$yellow-transp: rgba(242, 201, 76, 0.2);
$turquoise: #03FFD2;
$gray1: rgba(255, 255, 255, 0.85);
$gray2: rgba(255, 255, 255, 0.45);
$gray3: #324867;
$gray4: #304158;
$font-color: rgba(255, 255, 255, 0.65);
$input-border: rgba(255, 255, 255, 0.15);

// #FONTS
$primary-font: 'Montserrat', sans-serif;

$font-size: 13px;

$breadcrumb-height: 54px;
