@import "~styles/theme/index";

.#{$wf__ns}engineering-sof-tab {
    height: 67vh;
    overflow-y: auto;

  &__row {
    > .ant-col-12 {
      display: flex;
      flex-direction: column;
      flex: 0 0 calc(50% - 10px);
      max-width: calc(50% - 10px);

      $collapse: ant-collapse;

      & > .#{$collapse},
      & > .#{$collapse} > .#{$collapse}-item,
      & > .#{$collapse} > .#{$collapse}-item > .#{$collapse}-content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }
    }
  }

  > *:not(:last-child) {
    margin-bottom: 2em;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 1em 0;
  }
}
