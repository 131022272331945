@import "~styles/theme/index";

.#{$wf__ns}view-element {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 13px;
  color: $font-color;

  &__label {
    text-align: end;
  }

  &__divider {
    padding: 0 8px 0 2px;
  }

  &__element {
    color: $white;
    font-size: 14px;
  }

  &__label,
  &__element {
    width: 50%;
  }
}
