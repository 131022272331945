@import "~styles/theme/index";

.#{$wf__ns}leasing-workflow {
    .checkbox-text {
        color: $white;
        font-weight: 500;
        font-size: 13px;
        line-height: 13px;
        margin-left: 10px;
    }

    .engineering-panel,
    .leasing-panel {
      margin-bottom: 24px;
    }

    .engineering-panel,
    .leasing-panel,
    .structural-panel {
        .ant-collapse-content {
            padding: 40px 30px;
        }

        .ant-select-selector,
        .ant-input-number {
            height: 40px;
            display: flex;
            align-items: center;
          }

      &__row-wrapper {
        display: flex;
        justify-content: center;

        .structural-tower-percent {
          display: flex;
          justify-content: flex-end;
        }

        .adequate {
          display: flex;
          flex-wrap: nowrap;
          margin-top: 10px;
          padding-left: 0;
        }
      }
    }

    .tabs-wrap {
      overflow-y: auto;
      max-height: 61vh;

      &.categories-table-open {
        max-height: 41vh;
      }

      &_with-actions {
        max-height: 57vh;

        &.categories-table-open {
          max-height: 35vh;
        }
      }

      .ant-collapse > .ant-collapse-item > .ant-collapse-content {
        height: max-content;
        margin-bottom: 0;
      }
    }
}
