@import "~styles/theme/index";

.#{$wf__ns}admin-driver-management-modal {
  .ant-modal-content {
    .ant-modal-body {
      padding-top: 30px;

      .ant-row.ant-form-item {
        justify-content: center;
        align-items: center;

        &.aligned-form-item {
          align-items: flex-start;
        }

        .ant-input-number {
          height: 40px;
        }
      }
    }

    .ant-modal-footer {
      margin-left: 187px;
      padding-bottom: 30px;

      .ant-btn {
        width: 150px;
        margin-right: 35px;
      }
    }
  }
}
