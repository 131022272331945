@import "~styles/theme/index";

.#{$wf__ns}equipment-sectors {
  & > .ant-collapse:not(:last-child) {
    margin-bottom: 2em;

  }
  .ant-select {
    min-width: 100px
  }
}
